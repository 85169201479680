import {
    useContext, useCallback, useMemo, useState, useEffect
} from 'react';
import UserContext, {
    StoreContext,
    UserProfileContext
} from './getContext';
import moment from 'moment'

export const useStorePackStats =() => {
    const { time } = useContext(UserContext);
    const { profileData } = useContext(UserProfileContext) || {};
    const { cartData, packList } = useContext(StoreContext) || {};

    const [packListActive, setPackListActive] = useState(null)

    const cartTotalSum = cartData?.total_marketing_price;

    const isPackBlockedOrHidden = useCallback((pack) => {
        const { block_from, block_type } = pack?.settings || {}
        if (pack?.id === 4 && !pack?.can_buy && !moment(time())?.isBefore(moment(pack?.can_buy_before_formatted, 'DD.MM.YYYY HH:mm'))) {
            return true
        }
        if (block_from) {
            const dateRegister = moment(profileData?.created_at_formatted, 'DD.MM.YYYY HH:mm').format('x');
            const blockDate = moment(`${block_from} 00:00`, 'YYYY-MM-DD hh:mm').format('x');
            return (block_type === 'new' && dateRegister >= blockDate || block_type === 'all') && !moment(time())?.isBefore(block_from);
        }
        return false;
    }, [profileData?.created_at_formatted, time]);

    const currentUserPack = packList?.find(pack => profileData?.marketing_profile?.package?.id === pack?.id)
    const lastPack = packListActive?.length > 0 ? packListActive?.[packListActive?.length - 1]: null

    const initValue = useMemo(() => {
        const nextPackIdx = packListActive?.findIndex(pack => pack?.id === currentUserPack?.id) >= 0
            ? packListActive?.findIndex(pack => pack?.id === currentUserPack?.id) + 1
            : packListActive?.findIndex(pack => pack?.id === currentUserPack?.id + 1) >= 0
                ? packListActive?.findIndex(pack => pack?.id === currentUserPack?.id + 1)
                : packListActive?.findIndex(pack => pack?.id === currentUserPack?.id + 2) >=0
                    ? packListActive?.findIndex(pack => pack?.id === currentUserPack?.id + 2)
                    : packListActive?.findIndex(pack => pack?.id === currentUserPack?.id + 3) >=0
                        ? packListActive?.findIndex(pack => pack?.id === currentUserPack?.id + 3)
                        : null
        return {
            current: currentUserPack || null,
            next: currentUserPack?.id && (currentUserPack?.id === lastPack?.id || currentUserPack?.id === 4 || nextPackIdx !== 0 && !nextPackIdx)
                ? null
                : currentUserPack?.id && (nextPackIdx === 0 || nextPackIdx)
                    ? packListActive?.[nextPackIdx]
                    : packListActive?.[0],
            left: currentUserPack?.id && (currentUserPack?.id === lastPack?.id || currentUserPack?.id === 4 || nextPackIdx !== 0 && !nextPackIdx)
                ? null
                : currentUserPack?.id && currentUserPack?.id === 1 && packListActive[nextPackIdx]?.id === 2
                    ? packListActive?.[nextPackIdx]?.price - packListActive?.[nextPackIdx]?.user_total_personal_volume - cartTotalSum
                    : currentUserPack?.id
                        ? packListActive?.[nextPackIdx]?.price - cartTotalSum
                        : packListActive?.[0]?.price - cartTotalSum - (packListActive?.[0]?.id < 3 ? packListActive?.[0]?.user_total_personal_volume : 0)
        }
    }, [cartTotalSum, currentUserPack, lastPack?.id, packListActive])

    const packStat = packListActive?.reduce((acc, pack, idx, arr) => {
        if (pack?.id <= currentUserPack?.id) return acc;
        const cartSumPlusAccumulated = pack?.id === 1 && arr[idx + 1]?.id === 2
            ? cartTotalSum + pack?.user_total_personal_volume
            : cartTotalSum;
        const sumHas = pack?.id < 3
            ? cartTotalSum + pack?.user_total_personal_volume
            : cartTotalSum;
        if (sumHas >= pack?.price) {
            acc.current = pack;
            acc.next = pack?.id === lastPack?.id
                ? null
                : arr[idx + 1];
            acc.left = pack?.id === lastPack?.id
                ? ''
                : arr[idx + 1]?.price - cartSumPlusAccumulated;
        }
        return acc;
    }, initValue);

    const cartSumPlusAccumulated = packStat?.current?.id < 3 && currentUserPack?.id !== 2
        ? cartTotalSum + packStat?.current?.user_total_personal_volume
        : cartTotalSum;

    const repurchaseAmount = useMemo(() => {
        if (!cartSumPlusAccumulated || packStat?.current?.id < currentUserPack?.id) {
            return null
        } else if ((lastPack?.id === 1 && packStat?.current?.id === 1 || packStat?.next?.id > 2 || packStat?.current?.id >= 2) && (packStat?.current?.id <= currentUserPack?.id || (packStat?.current?.id > currentUserPack?.id || !currentUserPack?.id) && cartSumPlusAccumulated > packStat?.current?.price)) {
            if (packStat?.current?.id <= currentUserPack?.id) {
                return cartSumPlusAccumulated
            } else {
                return cartSumPlusAccumulated - packStat?.current?.price
            }
        } else {
            return null
        }
    }, [cartSumPlusAccumulated, currentUserPack?.id, lastPack?.id, packStat])


    useEffect(() => {
        setPackListActive(packList?.filter((pack) => !isPackBlockedOrHidden(pack)))
    }, [isPackBlockedOrHidden, packList]);

    return {
        packStat,
        packListActive,
        isPackBlockedOrHidden,
        currentUserPack,
        lastPack,
        repurchaseAmount
    }
}