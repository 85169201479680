import React, {
    useContext,
    useState,
    useMemo,
    useEffect
} from 'react';
import {
    Div,
    svgSprite
} from '../../../hybrid/wrappers';
import { StoreContext } from '../../../common/utils/getContext';
import T from '../../../common/components/T';
import Display from '../../../common/components/Display';
import PackItem from './PackItem';
import Loader from '../../../widgets/plug/Loader';
import Styles from '../../../common/decorators/Styles';
import getStyles from '../../../project/styles/widget-styles/cart-styles';
import { packVariantList } from '../../../common/utils/datas';
import { useWindowDimensions } from '../../../common/utils/windowDimensions';
import { useStorePackStats } from '../../../common/utils/useStorePackStat';


const PackProgress = ({
    styles, type, accordion = false
}) => {
    const { cartData } = useContext(StoreContext);
    const {
        packStat, packListActive, isPackBlockedOrHidden, currentUserPack, lastPack, repurchaseAmount
    } = useStorePackStats()
    const widthDimensions = useWindowDimensions();
    const cartTotalSum = cartData?.total_marketing_price;

    const [showDetails, setShowDetails] = useState(true);
    const [mobile, setMobile] = useState('');
    useEffect(() => {
        widthDimensions < 768
            ? setMobile(true)
            : setMobile(false);
    }, [widthDimensions]);

    const nextPackStyles = useMemo(() => {
        return packVariantList?.find(el => el?.id === packStat?.next?.id);
    }, [packStat?.next?.id]);
    const currentPackStyles = useMemo(() => {
        return packVariantList?.find(el => el?.id === packStat?.current?.id);
    }, [packStat]);

    if (currentUserPack?.id === lastPack?.id) return null;
    if (!packListActive) return <Loader compStyles={{ loader: { height: '200px' } }}/>;
    return (
        <>
            {cartData?.items?.length > 0
                ?
                <Div styles={{
                    ...styles.packBlockWrap, ...accordion
                        ? {}
                        : { padding: '0' }
                }}>
                    <Div styles={styles.packProgressWrap}>
                        {packListActive?.length > 0 && packListActive?.map(pack => <PackItem key={pack?.id}
                            styles={styles}
                            pack={pack}
                            isPackBlockedOrHidden={isPackBlockedOrHidden}
                            packStat={packStat}
                            cartTotalSum={cartTotalSum}
                            currentUserPack={currentUserPack}
                            type={type}
                            packListActive={packListActive}
                        />)}
                    </Div>
                    <Div styles={styles.statPackSection}>
                        {accordion && <Div styles={{
                            ...styles.statAccordion, ...showDetails
                                ? {
                                    transition: styles.variable.transition,
                                    transform: 'rotateZ(180deg)'
                                }
                                : {}
                        }} onClick={() => setShowDetails(pre => !pre)}>
                            {svgSprite('angle-down', {
                                fill: styles.variable.greySuperDarkColor, width: 16, height: 8
                            })}
                        </Div>}
                        {showDetails && <Div styles={{
                            ...styles.statPackWrap, ...type === 'cart' || type === 'cart_widget'
                                ? { margin: '0' }
                                : {}
                        }}>
                            {type !== 'cart'
                                ? <>
                                    <Div styles={styles.statPackItem}>
                                        <Div styles={styles.statTitle}>
                                            <T textName='storeCurrentPack' defaultStr='Текущий пакет' page='/store'/>:
                                        </Div>
                                        {mobile
                                            ? packStat?.current?.name
                                                ? <Div styles={{
                                                    ...styles.packNameMob,
                                                    color: packVariantList?.find(item => packStat?.current?.id === item?.id)?.color
                                                }}>{packStat?.current?.name}</Div>
                                                : <Div
                                                    styles={{
                                                        ...styles.packNameMob,
                                                        color: styles.variable.darkColor
                                                    }}>-</Div>
                                            : <Div
                                                styles={{ ...styles.packNameWrap, ...styles[`package_${packStat?.current?.id || null}`] }}>
                                                <Div styles={styles.packIconWrap}>
                                                    {svgSprite(`pack_${packStat?.current?.id || null}`)}
                                                </Div>
                                                {packStat?.current?.name
                                                    ? <Div>{packStat?.current?.name}</Div>
                                                    : <Div styles={{ color: styles.variable.darkColor }}>-</Div>}
                                            </Div>}
                                    </Div>
                                    {packStat?.next && <Div styles={styles.statPackItem}>
                                        <Div styles={styles.statTitle}>
                                            <T textName='storeNextPack' defaultStr='Следующий пакет' page='/store'/>:
                                        </Div>
                                        {mobile
                                            ? <Div styles={{
                                                ...styles.packNameMob,
                                                color: packVariantList?.find(item => packStat?.next?.id === item?.id)?.color
                                            }}>{packStat?.next?.name}</Div>
                                            : <Div
                                                styles={{ ...styles.packNameWrap, ...styles[`package_${packStat?.next?.id || null}`] }}>
                                                <Div styles={styles.packIconWrap}>
                                                    {svgSprite(`pack_${packStat?.next?.id || null}`)}
                                                </Div>
                                                <Div>{packStat?.next?.name}</Div>
                                            </Div>}
                                    </Div>}
                                    {packStat?.next && <Div styles={styles.statPackItem}>
                                        <Div styles={{ ...styles.statTitle }}><T
                                            textName='storeNextPackAmount' defaultStr='До следующего пакета'
                                            page='/store'/>:</Div>
                                        <Div styles={{
                                            ...styles.leftAmount,
                                            background: nextPackStyles?.leftBg || 'rgba(169, 133, 111, 0.20)'
                                        }}><Display type='value' data={{
                                                formatted: true,
                                                value: packStat?.left
                                                    ? packStat?.left?.toFixed(2)
                                                    : '0.00', currency: { symbol: 'PV' }
                                            }}/></Div>
                                    </Div>}
                                </>
                                : <Div styles={{
                                    display: 'flex', 'align-items': 'center', 'margin-right': '8px'
                                }}>
                                    <Div styles={{ 'margin-right': '4px' }}>
                                        <T textName='storeAmountInCart' defaultStr='Набрано продукции на сумму'
                                            page='/store'/>:
                                    </Div>
                                    <Div styles={{
                                        ...styles.leftAmount,
                                        padding: '8px',
                                        background: currentPackStyles?.leftBg || 'rgba(169, 133, 111, 0.20)'
                                    }}>
                                        {cartData?.total_marketing_price_formatted}
                                    </Div>
                                </Div>}
                            {repurchaseAmount
                                ? <Div styles={{
                                    display: 'flex', 'align-items': 'center', color: '#7B7979'
                                }}>
                                    <Div styles={{
                                        ...styles.totalMarketingPrice,
                                        margin: '0 8px 0 0'
                                    }}>
                                        <Display type={'value'}
                                            data={{
                                                formatted: true,
                                                value: repurchaseAmount ? repurchaseAmount?.toFixed(2): '0.00',
                                                currency: { symbol: 'PV' }
                                            }}/>
                                    </Div>
                                    <T textName='cartPackTitle44a' defaultStr='пойдут как повторная покупка' page='all'/>
                                </Div>
                                : null}
                        </Div>}
                    </Div>
                </Div>
                : null
            }
        </>
    );
};

export default Styles(PackProgress, getStyles);