import React from 'react';
import styled, { css } from 'styled-components';

const stringStyles = (data) => data && Object.keys(data).length
    ? Object.keys(data).reduce((accum, key) => `${accum + key}:${data[key]};`, '')
    : '';

const getotherProps = (data) => Object.keys(data).reduce((accum, name) => {
    if (name !== 'onChangeText' && name !== 'styles' && name !== 'effects'
        && name !== 'teg' && name !== 'children' && name !== 'tagName'
        && name !== 'source' && name !== 'conttype' && name !== 'conttypeStyled') { accum[name] = data[name]; }

    if (name === 'childrenRef') { accum.ref = data[name]; }

    return accum;
}, {});

const getFullStyle = (data, effects) => effects && Object.keys(effects)
    ? Object.keys(effects).reduce((accum, effect) =>
        `${accum}&:${effect}{${stringStyles(effects[effect])}}`
    , stringStyles(data))
    : stringStyles(data);

const StyledComponent = styled.div`${(props) => props.styles && css`${props.styles}`}`;

const getSrc = (src) => {
    if (!process.env.NEXT_PUBLIC_DOMAINS) return src;
    const array = process.env.NEXT_PUBLIC_DOMAINS.split('|');
    let newSrc = src;
    if (typeof window != 'undefined'){
        let URL = array.find((d) => (process.env.NEXT_PUBLIC_ISCOPY ? 'ut.' : '') + d === window.location.host);
        if (URL && URL !== array[0]){
            newSrc = src.replace(array[0], URL)
        }
    }
    return newSrc;
};

export const WebComponent = (props) => {
    const {
        styles, effects, teg, children, tagName
    } = props;
    const otherProps = getotherProps(props);

    if (otherProps.src){
        otherProps.src = getSrc(otherProps.src)
    }
    if (children) { return <StyledComponent styles={getFullStyle(styles, effects)} as={tagName || teg} {...otherProps}>{children}</StyledComponent>; }

    return <StyledComponent styles={getFullStyle(styles, effects)} as={tagName || teg} {...otherProps} />;
};