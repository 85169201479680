export const getPlaceholderColor = (
    variationStyle, styles, compStyle, errors, focused, disabled
) => {
    const colorErrors = errors && errors.length ? compStyle?.inputError?.color || styles[`formControlError__${variationStyle}`]?.color : false;
    const colorDisabled = disabled ? compStyle?.inputDisabled?.color || styles['formControlDisabled']?.color : false;
    const colorFocus = focused ? compStyle?.inputFocus?.color || styles[`formControlFocus__${variationStyle}`]?.color : false;
    const colorDefault = compStyle?.input?.color || styles[`formControl__${variationStyle}`]?.color;

    const color = colorErrors || colorDisabled || colorFocus || colorDefault

    return {
        ...styles.placeholder,
        color
    }
}
export const getDefaultVariation = (context, title) => title ? 'couple_title' : 'no_title';
export const getIsCoupleVariation = (variation) => ['couple_title'].includes(variation)