import React, { useState, useContext } from 'react'
import { Div, Input, Tooltip } from '../../hybrid/wrappers'
import UserContext, { StoreContext } from '../../common/utils/getContext'
import { dataSender, navigate } from '../../common/utils/router';
import { API_SERVER_URL } from '../../common/utils/utils';
import { useRouter } from 'next/router';
import T from "../../common/components/T";

const CartCounter = ({
    styles,
    count,
    idProduct,
    funcs,
    price = false,
    updateDelivery,
    setUpdateDelivery,
    blockNum
}) => {
    const {
        userToken, lang, setAlertData, localization
    } = useContext(UserContext)
    const { cartData, setCartData } = useContext(StoreContext) ? useContext(StoreContext) : {}
    const [counter, setCounter] = useState(count)

    const setCalcProduct = async (countValue) => {
        let type = 'set-product'
        if (countValue === '') {
            countValue = 1
            // type = 'remove-product?_method=DELETE'
        }
        if (cartData.id){
            dataSender(
                `${API_SERVER_URL}/api/v1/shop/carts/${cartData.id}/${type}`,
                'POST',
                {
                    product_id: idProduct,
                    quantity: +countValue
                },
                (response) => {
                    setCounter(+countValue)
                    setCartData(response.data?.data)
                    setUpdateDelivery(!updateDelivery)
                },
                () => {
                    setAlertData({
                        type: 'error', cont: true, default: 'error_get_data'
                    })
                },
                userToken,
                lang,
                localization?.currency
            );
        }
    }

    const setProduct = async () => {
        if (cartData.id){
            dataSender(
                `${API_SERVER_URL}/api/v1/shop/carts/${cartData.id}/set-product`,
                'POST',
                {
                    product_id: idProduct,
                    quantity: counter + 1
                },
                (response) => {
                    setCounter(counter + 1)
                    setCartData(response.data?.data)
                    setUpdateDelivery(!updateDelivery)
                },
                () => {
                    setAlertData({
                        type: 'error', cont: true, default: 'error_get_data'
                    })
                },
                userToken,
                lang,
                localization?.currency
            );
        }
    }

    const router = useRouter()
    const removeProduct = async () => {
        if (cartData.id){
            dataSender(
                `${API_SERVER_URL}/api/v1/shop/carts/${cartData.id}/remove-product?_method=DELETE`,
                'POST',
                {
                    product_id: idProduct,
                    quantity: 1
                },
                (response) => {
                    setCounter(counter - 1)
                    setCartData(response.data?.data)
                    setUpdateDelivery(!updateDelivery)
                    if ((router.asPath === `/${lang}/store/cart` || router.asPath === '/store/cart') && !response?.data?.data?.items?.length){
                        navigate('/[lang]/store', '/store', lang);
                    }
                },
                () => {
                    setAlertData({
                        type: 'error', cont: true, default: 'error_delete_data'
                    })
                },
                userToken,
                lang,
                localization?.currency
            );
        }
    }
    return (
        <Div styles={price ? { ...styles.cartItemCounter, ...styles.cartItemCounterGrid } : styles.cartItemCounter}>
           {blockNum === 1 ? <Tooltip text={<T textName='storeCartIsNotAvailableBuCount' defaultStr='Вы достигли лимита по возможности покупки данного товара' page={'/store'} />}>
            <Div
                    data-uitest='531_uitest' 
                    styles={{...styles.cartItemCounterCircle, ...(blockNum && blockNum === 1) ? {opacity: "0.5"} : {} }}
                >-</Div>
           </Tooltip> :  <Div
                data-uitest='531_uitest' 
                onClick={funcs && funcs.counter ?
                    () => funcs.counter(idProduct, 'remove'):
                    () => removeProduct()}
                // onClick={(blockNum && blockNum === 1) ? () => {} : funcs && funcs.counter ?
                //     () => funcs.counter(idProduct, 'remove'):
                //     () => removeProduct()}
                styles={{...styles.cartItemCounterCircle}}
                // styles={{...styles.cartItemCounterCircle, ...(blockNum && blockNum === 1) ? {opacity: "0.5", cursor: 'auto'} : {} }}
                effects={{ hover: { ...styles.cartItemCounterCircleHover} }}
            >-</Div>}
            <Div styles={styles.cartItemNumber}>
                <Input
                    styles={styles.cartItemCounterValue}
                    value={count}
                    disabled={blockNum === 1}
                    effects={{
                        hover: styles.cartItemCounterValueFocus,
                        focus: styles.cartItemCounterValueFocus
                    }}
                    data-uitest='532_uitest' onClick={(e) => {
                        e.target.select();
                    }}
                    onChange={(e) => {
                        funcs && funcs.counter ?
                        funcs.counter(idProduct, 'change', (blockNum && +e.target.value > blockNum) ? blockNum : +e.target.value):
                        setCalcProduct((blockNum && +e.target.value > blockNum) ? blockNum : +e.target.value)
                    }}
                />
            </Div>
             {/* <Div
                data-uitest='533_uitest' onClick={(blockNum && blockNum === counter) ? ()=>{} :funcs && funcs.counter ?
                    () => funcs.counter(idProduct, 'set'):
                    () => setProduct()}
                styles={{...styles.cartItemCounterCircle, ...(blockNum && blockNum === counter) ? {opacity: "0.5", cursor: 'auto'} : {}}}
                effects={{ hover: { ...styles.cartItemCounterCircleHover } }}
            >+</Div> */}
            {blockNum && blockNum === counter ? <Tooltip text={<T textName='storeCartIsNotAvailableBuCount' defaultStr='Вы достигли лимита по возможности покупки данного товара' page={'/store'} />}>
                <Div
                    data-uitest='533_uitest' 
                    styles={{...styles.cartItemCounterCircle, ...(blockNum && blockNum === counter) ? {opacity: "0.5"} : {}}}
                    // effects={{ hover: { ...styles.cartItemCounterCircleHover } }}
                >+</Div>
            </Tooltip> :  <Div
                data-uitest='533_uitest' onClick={(blockNum && blockNum === counter) ? ()=>{} :funcs && funcs.counter ?
                    () => funcs.counter(idProduct, 'set'):
                    () => setProduct()}
                styles={{...styles.cartItemCounterCircle, ...(blockNum && blockNum === counter) ? {opacity: "0.5", cursor: 'auto'} : {}}}
                effects={{ hover: { ...styles.cartItemCounterCircleHover } }}
            >+</Div>}
           
        </Div>
    )
}
export default CartCounter