// @generated: @expo/next-adapter@2.1.5
import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '../../../hybrid/wrappers';
import uikit from '../../../project/uikit/styles';

const Prices = ({ val, styles }) => {
    return (
        <Div>
            <Div styles={uikit.noWrap}>{val.total_price_formatted}</Div>
            {val.total_marketing_price_formatted && <Div styles={styles.markPrice}>{val.total_marketing_price_formatted}</Div>}
        </Div>
    )
}

Prices.propTypes = {
    getHandlers: PropTypes.func,
    gridStyles: PropTypes.object,
    val: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool
    ]),
    styles: PropTypes.object,
    name: PropTypes.string
};

export default Prices;